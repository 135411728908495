import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useRef } from "react";
import AnimateWhenVisible from "../components/AnimateWhenVisible";
import { WaveTop } from "../components/Waves";

const Productos = () => {
    let titleRef = useRef(null);
    return (
        <div className="container mx-auto px-2 flex flex-wrap pt-4 pb-12 md:w-2/3">

            <AnimateWhenVisible childRef={titleRef} animationName="animate-slideInLeft" animateAlways={false}>
                <h2 ref={titleRef} className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
                    Productos
                </h2>
            </AnimateWhenVisible>

            <div className="w-full mb-4">
                <div className="h-1 mx-auto bg-gradient-to-r from-indigo-500 via-indigo-200 to-indigo-500 my-0 py-0 rounded-t"></div>
            </div>

            <div className="flex flex-grow flex-wrap border-r-2 border-l-2 border-indigo-400 border-opacity-25 my-4">
                <div className="w-full sm:w-1/2 p-6">
                    <h2 className="text-center md:text-right text-3xl text-gray-800 font-bold leading-none mb-3">Cámaras exteriores</h2>

                    <p className="text-justify text-gray-600 mb-8">
                        <span className="font-semibold">Perfectas para la supervisión externa de tu casa o empresa.</span><br />
                        Las cámaras de exterior son resistentes a la interperie y gracias a su montaje flexible se pueden instalar en cualquier lugar.
                    </p>

                    <Link to="/camaras/exteriores/" title="Ver más información sobre cámaras exteriores" className="text-black underline" target="_blank">
                        Más información sobre cámaras de exterior
                    </Link>
                </div>
                <div className="w-full sm:w-1/2 sm:p-6">
                    <Link to="/camaras/exteriores" title="Ver más información sobre cámaras exteriores" className="noicon">
                        <StaticImage
                            src="../images/m16.jpg"
                            alt="Cámara MOBOTIX&copy; M16 AllroundDual"
                            className="sm:rounded-xl lg:w-5/6 sm:h-64 mx-auto"
                        />
                    </Link>
                </div>
            </div>

            <div className="flex flex-grow flex-wrap flex-col-reverse sm:flex-row border-l-2 border-r-2 border-indigo-400 border-opacity-25 my-4">
                <div className="w-full sm:w-1/2 sm:p-6 mt-6">
                    <Link to="/camaras/interiores" title="Ver más información sobre cámaras interiores" className="noicon">
                        <StaticImage
                            src="../images/c26.jpg"
                            alt="Cámara MOBOTIX&copy; C26 Indoor 360º"
                            className="sm:rounded-xl lg:w-5/6 sm:h-64 mx-auto"
                        />
                    </Link>
                </div>
                <div className="w-full sm:w-1/2 p-6">
                    <div className="align-middle">
                        <h2 className="text-center md:text-left text-3xl text-gray-800 font-bold leading-none mb-3">Cámaras interiores</h2>

                        <p className="text-justify text-gray-600 mb-8">
                            <span className="font-semibold">Para vigilar de cerca dentro de tu casa, oficina, tienda, o ¡donde tú quieras!</span><br />
                            Las cámaras de interior están preparadas para que no pierdas detalle de todo lo que ocurre en tu local u oficina.
                        </p>

                        <Link to="/camaras/interiores" title="Ver más información sobre cámaras interiores" className="text-black underline" target="_blank">
                            Más información sobre cámaras de interior
                        </Link>
                    </div>
                </div>
            </div>

            <div className="flex flex-grow flex-wrap border-r-2 border-l-2 border-indigo-400 border-opacity-25 my-4">
                <div className="w-full sm:w-1/2 p-6">
                    <h2 className="text-center md:text-right text-3xl text-gray-800 font-bold leading-none mb-3">Videoporteros</h2>

                    <p className="text-justify text-gray-600 mb-8">
                        <span className="font-semibold">Videoportero Hemispheric. Más visión, más seguridad.</span><br />
                        Con el videoportero IP Hemispheric de alta resolución, MOBOTIX ofrece una solución innovadora, potente y fácil de instalar y utiliza el estándar internacional de videotelefonía VoIP/SIP.
                    </p>

                    <Link to="/videoporteros" title="Ver más información sobre cámaras exteriores" className="text-black underline" target="_blank">
                        Más información sobre videoporteros
                    </Link>
                </div>
                <div className="w-full sm:w-1/2 sm:p-6">
                    <Link to="/videoporteros" title="Ver más información sobre cámaras exteriores" className="noicon">
                        <StaticImage
                            src="../images/t26.jpg"
                            alt="Cámara MOBOTIX&copy; M16 AllroundDual"
                            className="sm:rounded-xl lg:w-5/6 sm:h-64 mx-auto"
                        />
                    </Link>
                </div>
            </div>

        </div>
    );
};

export default Productos;