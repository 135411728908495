import React, { RefObject, useEffect } from "react";

type AnimateWhenVisibleProps = {
    children?: React.ReactNode,
    animationName: string,
    animationDuration?: string,
    animationDelay?: string,
    animateAlways?: boolean,
    childRef: RefObject<HTMLElement>
};

const AnimateWhenVisible = ({
    children,
    animationName,
    animationDuration = "1s",
    animationDelay = null,
    animateAlways = false,
    childRef
}: AnimateWhenVisibleProps) => {
    const observeCallback = (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
            entry.target.classList.add(animationName);
        } else {
            if (animateAlways)
                entry.target.classList.remove(animationName);
        }
    };

    useEffect(() => {
        if (!childRef?.current)
            return;

        childRef.current.style.animationDuration = animationDuration;
        if (animationDelay != null)
            childRef.current.style.animationDelay = animationDelay;

        const observer = new IntersectionObserver(observeCallback);
        observer.observe(childRef.current);
        return () => {
            try {
                observer.unobserve(childRef.current);
            } catch { }
        };
    }, [childRef]);

    return (
        <>
            {children}
        </>
    );
};

export default AnimateWhenVisible;