import React from "react";
import "./LoadingSpinner.css";

const LoadingSpinner = ({ size = 32, backgroundColor = "gray", color = "#3498db" }) => {
    return (
        <div className="flex justify-center items-center">
            <div className={`loader ease-linear rounded-full border-8 border-t-8 h-${size} w-${size}`} style={{ borderColor: backgroundColor, borderTopColor: color }}></div>
        </div>
    );
};

export default LoadingSpinner;