import React, { useRef } from "react";
import AnimateWhenVisible from "../components/AnimateWhenVisible";
import GoogleMapReact from "google-map-react";
import { StaticImage } from "gatsby-plugin-image";

const MapMarker = ({ lat, lng }) =>
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width="24" height="32"
        viewBox="0 0 226 226"
        style={{ fill: "#000000" }}>
        <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: "normal" }}>
            <path d="M0,226v-226h226v226z" fill="none"></path>
            <g fill="#3498db">
                <path d="M58.76,0c-22.67838,0 -36.63729,2.27613 -45.1382,4.68773c-4.25046,1.2058 -7.13592,2.44924 -9.04883,3.46945c-1.01351,0.54054 -1.1208,0.71818 -1.67734,1.13c-1.4402,0.5584 -2.49558,1.81353 -2.79852,3.3282c-0.0311,0.1488 -0.05467,0.29906 -0.07062,0.45023c-0.01519,0.13783 -0.02402,0.27628 -0.02648,0.41492c-0.00005,0.01177 -0.00005,0.02354 0,0.03531c-0.00007,0.01471 -0.00007,0.02943 0,0.04414v122.04v85.88c-0.02305,1.63007 0.83338,3.14629 2.24135,3.96805c1.40797,0.82176 3.14934,0.82176 4.55731,0c1.40797,-0.82176 2.2644,-2.33798 2.24135,-3.96805v-82.67539c1.23641,-0.57336 3.0487,-1.59437 9.97578,-3.33703c8.63127,-2.17139 21.81024,-4.38758 39.74422,-4.38758c17.56197,0 30.63566,4.31376 46.55953,8.86344c15.92387,4.54967 34.52003,9.21656 61.92047,9.21656c31.63325,0 56.46469,-14.14266 56.46469,-14.14266c1.41914,-0.80251 2.2962,-2.30701 2.29531,-3.93734v-113c0.00169,-1.60303 -0.84572,-3.08706 -2.22715,-3.90031c-1.38143,-0.81324 -3.09023,-0.83406 -4.49106,-0.05469c-3.69524,2.05291 -24.76156,12.995 -52.0418,12.995c-21.696,0 -36.76034,-6.41957 -52.63328,-13.19805c-15.87294,-6.77848 -32.55959,-13.92195 -55.84672,-13.92195z"></path>
            </g>
        </g>
    </svg>;

const About = () => {
    let titleRef = useRef(null);

    const mapOptions = {
        center: {
            lat: 41.544229712899565,
            lng: 2.1007554554936134,
            alt: 17
        },
        zoom: 17
    };

    return (
        <>
            <AnimateWhenVisible childRef={titleRef} animationName="motion-safe:animate-slideInLeft" animateAlways={false}>
                <h2 ref={titleRef} className="w-full my-2 text-5xl font-bold leading-tight text-center text-white">
                    Acerca de <span className="font-light">EnTTia</span>
                </h2>
            </AnimateWhenVisible>

            <div className="w-full mb-4">
                <div className="h-1 mx-auto bg-gradient-to-r from-indigo-50 via-indigo-200 to-indigo-50 my-0 py-0 rounded-t"></div>
            </div>

            <div className="flex flex-col lg:flex-row p-6 shadow-3xl bg-gradient-to-r from-indigo-100 via-white to-indigo-100">

                <div id="about-info" className="w-full lg:w-1/2 p-6">
                    <p className="whitespace-pre-line">
                        Enttia es una ingeniería especializada en implementar la tecnología Mobotix de videovigilancia, control de presencia laboral y control de accesos:
                    </p>
                    <br />
                    <ul className="pl-6 list-disc">
                        <li>
                            <span className="font-bold">Amplia trayectoria</span>: Somos una de las empresas de videovigilancia, instalación de cámaras de seguridad, instalación de CCTV, control de accesos y control horario con más experiencia del sector.
                        </li>

                        <li>
                            <span className="font-bold">Calidad y fiabilidad</span>: Nuestros sistemas son ideales para casos de detección de incendios, accesos a zonas no permitidas, detección de movimiento y seguridad perimetral exterior.
                        </li>

                        <li>
                            <span className="font-bold ">Garantía</span>: De hasta 2 años ampliable según el servicio o producto contratado.
                        </li>

                        <li>
                            <span className="font-bold">Renting</span>: Posibilidad de alquilar sus equipos, según sistema contratado.
                        </li>
                    </ul>


                </div>

                <div id="about-geo" className="w-full h-96 lg:h-auto lg:w-1/2 rounded px-2">
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyCterCvTVjxFnMRPlydS5Pr4Hxel48ysb4" }}
                        defaultCenter={mapOptions.center}
                        defaultZoom={mapOptions.zoom}
                    >

                        <MapMarker
                            lat={mapOptions.center.lat}
                            lng={mapOptions.center.lng}
                        />
                    </GoogleMapReact>
                </div>

            </div>
        </>
    );
};

export default About;