import React from "react";
import Layout from "../layout/Layout";
import Hero from "../sections/Hero";
import CTA from "../sections/CTA";
import Clientes from "../sections/Clientes";
import Productos from "../sections/Productos";
import { WaveTop, WaveBottom } from "../components/Waves";
import About from "../sections/About";

const IndexPage = () => {
    return (
        <Layout title="Cámaras de videovigilancia y seguridad para tu empresa">

            <div id="inicio" className="pt-4 md:pt-24">
                <Hero />
            </div>

            <WaveTop backgroundColor="white" />

            <section id="productos" className="bg-white py-8">
                <Productos />
            </section>

            <section id="clientes" className="bg-white py-8">
                <Clientes />
            </section>

            <WaveBottom backgroundColor="white" />

            <section className="container mx-auto text-center py-6 mb-12">
                {/* <CTA title="¡Contáctanos!" buttonLabel="Llámanos" onButtonClick={() => console.log("llamar")} /> */}
                <About />
            </section>

        </Layout>
    );
};

export default IndexPage;
