import { StaticImage } from "gatsby-plugin-image";
import React, { CSSProperties, useRef } from "react";
import AnimateWhenVisible from "../components/AnimateWhenVisible";

type ClientCardProps = {
    title?: string,
    subtitle?: string,
    children,
    className?: string,
    style?: CSSProperties
};

const ClientCard = ({
    title,
    children,
    className,
    style
}: ClientCardProps) => {
    let cardRef = useRef(null);
    return (
        <AnimateWhenVisible childRef={cardRef} animationName="motion-safe:animate-slideInBottom" animateAlways={false}>
            <div
                className={className}
                ref={cardRef}
                style={style}
            >
                {children}

                <span className="text-xl font-light text-gray-800 text-center">
                    {title}
                </span>
            </div>
        </AnimateWhenVisible>
    );
};

const Clientes = () => {
    const imageContainerStyle = {
        maxWidth: "200px",
    };
    const imageLayout = "fullWidth";
    const imagePlaceholder = "tracedSVG";
    const imageStyle: CSSProperties = {
        objectFit: "scale-down",
        marginLeft: "auto", marginRight: "auto"
    };

    const clientCardDefaultClassName = "text-center";

    let titleRef = useRef(null);
    return (
        <div className="container mx-auto flex flex-wrap pt-4 pb-12 md:w-2/3">

            <AnimateWhenVisible childRef={titleRef} animationName="motion-safe:animate-slideInLeft" animateAlways={false}>
                <h2 ref={titleRef} className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
                    Confían en nosotros...
                </h2>
            </AnimateWhenVisible>

            <div className="w-full mb-4">
                <div className="h-1 mx-auto bg-gradient-to-r from-indigo-500 via-indigo-200 to-indigo-500 my-0 py-0 rounded-t"></div>
            </div>

            <div className="w-full mx-4 grid gap-4 grid-cols-2 sm:grid-cols-2 md:grid-cols-4 justify-center items-center">
                <ClientCard
                    className={clientCardDefaultClassName}
                    title="Generalitat de Catalunya"
                    style={imageContainerStyle}
                >
                    <StaticImage
                        src="../images/gencat.png"
                        alt="Logo de la Generalitat de Catalunya"
                        title="Logo de la Generalitat de Catalunya"

                        layout={imageLayout}
                        placeholder={imagePlaceholder}
                        imgStyle={imageStyle}
                    />
                </ClientCard>

                <ClientCard style={imageContainerStyle}>
                    <StaticImage
                        src="../images/mutuaterrassa2.png"
                        alt="Logo de Mútua Terrassa"
                        title="Logo de Mútua Terrassa"

                        layout={imageLayout}
                        placeholder={imagePlaceholder}
                        imgStyle={imageStyle}
                    />
                </ClientCard>

                <ClientCard style={imageContainerStyle}>
                    <StaticImage
                        src="../images/veteralia.png"
                        alt="Logo de Veteralia"
                        title="Logo de Veteralia"

                        layout={imageLayout}
                        placeholder={imagePlaceholder}
                        imgStyle={imageStyle}
                    />
                </ClientCard>

                <ClientCard style={imageContainerStyle}>
                    <StaticImage
                        src="../images/leitat2.png"
                        alt="Logo de Leitat"
                        title="Logo de Leitat"

                        layout={imageLayout}
                        placeholder={imagePlaceholder}
                        imgStyle={imageStyle}
                    />
                </ClientCard>

                {/*<ClientCard className={clientCardDefaultClassName} title="Luis Vuitton" style={imageContainerStyle}>
                    <StaticImage
                        src="../images/luisvuitton.png"
                        alt="Logo de Luis Vuitton"
                        title="Logo de Luis Vuitton"

                        layout={imageLayout}
                        placeholder={imagePlaceholder}
                        imgStyle={imageStyle}
                    />
                </ClientCard>*/}

                <ClientCard className={clientCardDefaultClassName} style={imageContainerStyle}>
                    <StaticImage
                        src="../images/spinreact.png"
                        alt="Logo de Spinreact"
                        title="Logo de Spinreact"

                        layout={imageLayout}
                        placeholder={imagePlaceholder}
                        imgStyle={imageStyle}
                    />
                </ClientCard>

                <ClientCard style={imageContainerStyle}>
                    <StaticImage
                        src="../images/sequra.png"
                        alt="Logo de Sequra"
                        title="Logo de Sequra"

                        layout={imageLayout}
                        placeholder={imagePlaceholder}
                        imgStyle={imageStyle}
                    />
                </ClientCard>

                <ClientCard style={imageContainerStyle}>
                    <StaticImage
                        src="../images/gpa-innova.png"
                        alt="Logo de GPA Innova"
                        title="Logo de GPA Innova"

                        layout={imageLayout}
                        placeholder={imagePlaceholder}
                        imgStyle={imageStyle}
                    />
                </ClientCard>

                <ClientCard style={imageContainerStyle}>
                    <StaticImage
                        src="../images/codorniu.png"
                        alt="Logo de Codorniu"
                        title="Logo de Codorniu"

                        layout={imageLayout}
                        placeholder={imagePlaceholder}
                        imgStyle={imageStyle}
                    />
                </ClientCard>
            </div>

        </div>
    );
};

export default Clientes;