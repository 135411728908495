import React, { useRef } from "react";
import ContactForm from "../components/ContactForm";
import Check from "../components/Check";
import AnimateWhenVisible from "../components/AnimateWhenVisible";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoiceDollar, faUserCheck, faHourglass, faHammer } from "@fortawesome/free-solid-svg-icons";

type FeatureProps = {
    label: string,
    className?: string,
    animationDuration?: string,
    icon: IconProp,
    iconSize?: SizeProp,
    iconClassName?: string
};

const Feature = ({
    label,
    className = "",
    icon,
    iconClassName = "",
    iconSize = "2x",
    animationDuration
}: FeatureProps) => {
    let thisRef = useRef(null);
    return (
        <AnimateWhenVisible childRef={thisRef} animationName="animate-fadeIn" animationDelay={animationDuration} animateAlways={false}>
            <div
                ref={thisRef}
                className={`inline-flex items-center px-3 py-2 ${className}`}
                style={{ opacity: 0 }}
            >
                <FontAwesomeIcon
                    icon={icon} size={iconSize}
                    className={iconClassName}
                />
                <span className="ml-2">{label}</span>
            </div>
        </AnimateWhenVisible>
    );
};

const Hero = () => {
    return (
        <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center md:w-4/5">

            <div className="flex flex-col w-full xl:w-3/5 justify-center items-start text-white text-center md:text-left md:pr-3 motion-safe:animate-slideInLeft" style={{ animationDuration: "1.2s" }}>

                <p className="uppercase tracking-loose w-full font-light">Distribuidores oficiales de MOBOTIX&copy;</p>

                <h1 className="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-b from-indigo-200 via-indigo-50 to-indigo-200">
                    Seguridad y videovigilancia para tu empresa
                </h1>

                <p className="leading-normal text-2xl mb-8">MOBOTIX&copy; es la empresa líder en el sector de <strong>videovigilancia</strong> inteligente y nosotros somos vendedores oficiales.</p>
                <p className="leading-normal text-xl mb-8">Contáctanos y te informaremos sobre soluciones adaptadas a tu empresa.</p>

                <div className="text-white text-left mb-12">
                    <Feature label="Atención personalizada" icon={faUserCheck} animationDuration="1s" />
                    <Feature label="Presupuesto gratuito y sin compromiso" icon={faFileInvoiceDollar} iconClassName="text-white" animationDuration="1.5s" />
                    <Feature label="Experiencia en el sector" icon={faHourglass} animationDuration="2s" />
                    <Feature label="Planificación, instalación y configuración integral" icon={faHammer} animationDuration="2.5s" />
                </div>

            </div>

            <div id="contacto" className="rounded bg-gradient-to-b from-blue-100 via-white to-blue-100 text-black w-full xl:w-2/5 p-6 shadow-3xl motion-safe:animate-slideInRight" style={{ animationDuration: "1.2s" }}>
                <ContactForm />
            </div>

        </div>
    );
};

export default Hero;